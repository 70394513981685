<template>
  <div class="container mt-5">
    <div class="alert alert-danger custom-alert" role="alert">Anda tidak memiliki akses</div>

    <!-- Button to navigate back to home -->
    <router-link to="/" class="btn btn-primary mt-3">Back to Home</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
.container {
  text-align: center;
}

.custom-alert {
  font-size: 2rem;
  padding: 30px;
}
</style>
